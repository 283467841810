import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";

export default class Tables extends Component {
  static contextType = AppContext;
  state = {
    areas: [],
    newTable: "",
    newArea: "",
    selectedArea: "",
  };
  componentDidMount() {
    db.collection("tables")
      .doc(this.context.outlet)
      .onSnapshot((doc) => {
        this.setState({ areas: doc.data().areas }, () =>
          console.log(this.state.areas)
        );
      });

    /* this.updateAll(); */
  }

  updateAll = () => {
    db.collection("tables")
      .doc(this.context.outlet)
      .update({
        areas: [
          {
            name: "Outdoor (by the side)",
            tableList: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
          },
          {
            name: "Outdoor (Sheltered)",
            tableList: ["S1", "S2", "S3", "S4", "S5"],
          },
          {
            name: "Outdoor (by the curb)",
            tableList: [
              "M1",
              "M2",
              "M3",
              "M4",
              "M5",
              "M6",
              "A1",
              "A2",
              "A3",
              "A4",
              "A5",
            ],
          },
          {
            name: "Indoor",
            tableList: ["C1", "C2", "C3", "C4", "C5", "C6", "C7"],
          },
          {
            name: "Bar",
            tableList: [
              "B1",
              "B2",
              "B3",
              "B4",
              "B5",
              "B6",
              "B7",
              "B8",
              "B9",
              "B10",
              "B11",
              "B12",
              "B13",
              "B14",
              "B15",
            ],
          },
          {
            name: "Lounge",
            tableList: ["L1", "L2", "L3", "L4"],
          },
          {
            name: "Alfresco",
            tableList: [
              "BY1",
              "BY2",
              "BY3",
              "BY4",
              "BY5",
              "BY6",
              "BY7",
              "BY8",
              "BY9",
              "BY10",
            ],
          },
        ],
      });
  };

  deleteTable = (areaIndex, tableIndex) => {
    let areasCopy = [...this.state.areas];

    areasCopy[areaIndex].tableList.splice(tableIndex, 1);

    db.collection("tables").doc(this.context.outlet).update({
      areas: areasCopy,
    });
  };

  deleteArea = (areaIndex) => {
    let areasCopy = [...this.state.areas];

    areasCopy.splice(areaIndex, 1);

    db.collection("tables").doc(this.context.outlet).update({
      areas: areasCopy,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  addTable = () => {
    let areasCopy = [...this.state.areas];
    let areaToAdd = areasCopy.findIndex(
      (item) => item.name === this.state.selectedArea
    );

    if (areaToAdd !== -1) {
      areasCopy[areaToAdd].tableList.push(this.state.newTable);
    }

    db.collection("tables").doc(this.context.outlet).update({
      areas: areasCopy,
    });
  };

  /* deleteBanner = (banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let bannerIndex = this.state.allCarousel.findIndex(
      (item) => item === banner
    );
    let newBanner = [...this.state.allCarousel];
    newBanner.splice(bannerIndex, 1);
    db.collection("Settings").doc(assetDoc).update({ allCarousel: newBanner });
  }; */

  addArea = () => {
    let areasCopy = [...this.state.areas];
    let exists = areasCopy.findIndex(
      (item) => item.name === this.state.newArea
    );
    if (exists !== -1) {
      alert("Area with that name already exists!");
      return;
    }
    areasCopy.push({
      name: this.state.newArea,
      tableList: [],
    });
    db.collection("tables").doc(this.context.outlet).update({
      areas: areasCopy,
    });
  };
  render() {
    return (
      <div className="admin-table-page">
        <div className="table-settings-header">
          <div className="header-input-box">
            <input
              type="text"
              value={this.state.newArea}
              id="newArea"
              onChange={this.onChange}
              placeholder="New Area"
            />
            <button onClick={this.addArea}>Add</button>
          </div>
          <div className="header-input-box">
            <select
              name="selectedArea"
              id="selectedArea"
              onChange={this.onChange}>
              <option value="select area">select area</option>
              {this.state.areas.map((area) => {
                return <option value={area.name}>{area.name}</option>;
              })}
            </select>
            <input
              type="text"
              value={this.state.newTable}
              id="newTable"
              onChange={this.onChange}
              placeholder="New Table"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.addTable();
                }
              }}
            />
            <button onClick={this.addTable}>Add</button>
          </div>
        </div>
        <div className="admin-area-grid">
          {this.state.areas.map((area, areaIndex) => {
            return (
              <div className="admin-area">
                <h3>{area.name}</h3>
                <button onClick={() => this.deleteArea(areaIndex)}>X</button>
                <div className="table-list">
                  {area.tableList.map((table, tableIndex) => {
                    return (
                      <span className="admin-table-item">
                        {table}{" "}
                        <button
                          onClick={() =>
                            this.deleteTable(areaIndex, tableIndex)
                          }>
                          X
                        </button>
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
