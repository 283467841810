import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Select from "react-select";
import { db } from "../Firebase/firebase";
import { message } from "flwww";
import { AppContext } from "../context/AppContext";

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

export default class MinuteBlock extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super();
    this.state = {
      selectedDays: [],
      loading: "Update",
      adminEmail: "",
      viewerEmail: "",
      date: "",
      dayOfWeek: "",
      hours: [],
      minutes: [],
      dateId: "",
      currentTimings: [],
      isVisible: false,
      hourSelected: "",
      options: [
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
        { value: 21, label: "21" },
        { value: 22, label: "22" },
      ],
      default: [
        { value: 8, label: "8", minutes: [] },
        { value: 9, label: "9", minutes: [] },
        { value: 10, label: "10", minutes: [] },
        { value: 11, label: "11", minutes: [] },
        { value: 12, label: "12", minutes: [] },
        { value: 13, label: "13", minutes: [] },
        { value: 14, label: "14", minutes: [] },
        { value: 15, label: "15", minutes: [] },
        { value: 16, label: "16", minutes: [] },
        { value: 17, label: "17", minutes: [] },
        { value: 18, label: "18", minutes: [] },
        { value: 19, label: "19", minutes: [] },
        { value: 20, label: "20", minutes: [] },
        { value: 21, label: "21", minutes: [] },
      ],
      defaultSaturday: [
        { value: 8, label: "8", minutes: [{ label: "00", value: 0 }] },
        {
          value: 9,
          label: "9",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 10, label: "10", minutes: [{ label: "00", value: 0 }] },
        {
          value: 11,
          label: "11",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 12, label: "12", minutes: [{ label: "00", value: 0 }] },
        {
          value: 13,
          label: "13",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 14, label: "14", minutes: [{ label: "00", value: 0 }] },
        { value: 15, label: "15", minutes: [{ label: "00", value: 0 }] },
        { value: 16, label: "16", minutes: [] },
        { value: 17, label: "17", minutes: [{ label: "00", value: 0 }] },
        {
          value: 18,
          label: "18",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 19, label: "19", minutes: [{ label: "00", value: 0 }] },
        { value: 20, label: "20", minutes: [] },
        { value: 21, label: "21", minutes: [] },
        { value: 22, label: "22", minutes: [{ label: "30", value: 30 }] },
      ],
      minOptions:
        context.outlet === "TCS"
          ? [
              { value: 0, label: "00" },
              { value: 30, label: "30" },
            ]
          : [
              { value: 0, label: "00" },
              { value: 15, label: "15" },
              { value: 30, label: "30" },
              { value: 45, label: "45" },
            ],
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onSelectChange2 = this.onSelectChange2.bind(this);
  }

  toggleModal = () => {
    // This function change the state of the modal visibility (e.g. this.state.modalIsVisible)
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleDayClick = (day, { selected, disabled }) => {
    this.setState({ hourSelected: [], minutesBlocked: [] });
    let date = day.getDate();
    let month = day.getMonth();
    let year = day.getFullYear();
    let dayOfWeek = day.getDay();
    db.collection("blocked")
      .where("date", ">=", new Date(year, month, date, 0, 0, 0, 0))
      .where("date", "<=", new Date(year, month, date, 23, 0, 0, 0))
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.size === 0) {
          if (dayOfWeek == 6) {
            this.setState({
              hours: [],
              minutes: this.state.defaultSaturday,
              dateId: "",
              currentTimings: [],
            });
          } else {
            this.setState({
              hours: [],
              minutes: this.state.default,
              dateId: "",
              currentTimings: [],
            });
          }
        } else {
          querySnapshot.forEach((doc) => {
            let info = doc.data();
            let timings = info.minutes.filter((item) => {
              return item.minutes.length !== 0;
            });
            let currentTimings = [];

            timings.map((item) => {
              item.minutes.map((item2) => {
                currentTimings.push({
                  label: `${item.label}:${item2.label}`,
                  value: `${item.label}:${item2.label}`,
                  isFixed: true,
                });
              });
            });

            this.setState({
              hours: info.hours,
              minutes: info.minutes,
              currentTimings,
              dateId: doc.id,
            });
          });
        }
      });
    if (disabled) {
      return;
    }
    this.setState({
      date: day,
      dayOfWeek: day.getDay(),
    });
  };
  onChange = (e) => {
    let { hours } = this.state;
    let check = hours.indexOf(e.target.value);
    if (check === -1) {
      this.setState({ hours: [...hours, e.target.value] });
    } else {
      hours.splice(check, 1);
      this.setState({ hours: [...hours] });
    }
  };

  checkedOrNot = (num) => {
    if (this.state.hours.indexOf(num) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  onSelectChange(SelectValue, { action, removedValue }) {
    let { value } = SelectValue;

    let hourData = this.state.minutes.find((item) => {
      return item.value === value;
    });

    this.setState({
      hourSelected: SelectValue,
      minutesBlocked: hourData.minutes,
    });
    console.log(value);
  }

  onSelectChange2(value, { action, removedValue }) {
    if (value === null) {
      value = [];
    }
    this.setState({ minutesBlocked: value });
  }

  updateMinutes = (e) => {
    e.preventDefault();
    let currentMinutes = [...this.state.minutes];
    let newMinutes = currentMinutes.map((item) => {
      if (item.value === this.state.hourSelected.value) {
        item.minutes = this.state.minutesBlocked;
        return item;
      } else {
        return item;
      }
    });
    if (this.state.dateId !== "") {
      db.collection("blocked")
        .doc(this.state.dateId)
        .update({ minutes: newMinutes });
      message("updated!", "success", 3);
    } else {
      db.collection("blocked").add({
        date: this.state.date,
        hours: this.state.hours,
        minutes: newMinutes,
        outlet: this.context.outlet,
      });
      message("updated!", "success", 3);
    }
  };
  render() {
    return (
      <div className="blocked-minute">
        <h1>Minutes</h1>
        <form>
          <div className="date-time full-width">
            {" "}
            <div className="form-group">
              <DayPicker
                onDayClick={this.handleDayClick}
                selectedDays={this.state.date}
                onDayChange={(day) => console.log(day)}
                showOverlay={true}
              />
            </div>
          </div>
          {this.state.date !== "" ? (
            <React.Fragment>
              <Select
                value={this.state.currentTimings}
                name="current"
                className="basic-multi-select full-width"
                classNamePrefix="select"
                menuPlacement="top"
                isMulti
                isClearable={false}
                styles={styles}
              />
              <Select
                value={this.state.hourSelected}
                name="hours"
                onChange={this.onSelectChange}
                options={this.state.options}
                className="basic-multi-select full-width"
                classNamePrefix="select"
                menuPlacement="top"
              />
              <Select
                isMulti
                value={this.state.minutesBlocked}
                name="minutes"
                onChange={this.onSelectChange2}
                options={this.state.minOptions}
                className="basic-multi-select full-width"
                classNamePrefix="select"
                menuPlacement="top"
              />
            </React.Fragment>
          ) : null}

          <button
            className="full-width"
            type="submit"
            onClick={this.updateMinutes}>
            {this.state.loading}
          </button>
        </form>
      </div>
    );
  }
}
