import React, { Component } from "react";
import AreaRow from "./AreaRow";

export default class Area extends Component {
  render() {
    let { areaName, tables, reservations, showCancelled } = this.props;
    return (
      <div className="area">
        <h3>{areaName}</h3>
        {tables.map((table) => {
          return (
            <div className="area-table">
              <h4>{table}</h4>
              <div className="area-box">
                {reservations.map((item) => {
                  if (showCancelled) {
                    if (item.tableNo === table || item.tableNo2 === table) {
                      return (
                        <AreaRow data={item} current={new Date()}></AreaRow>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    if (
                      (item.tableNo === table || item.tableNo2 === table) &&
                      !item.cancelled
                    ) {
                      return (
                        <AreaRow data={item} current={new Date()}></AreaRow>
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
