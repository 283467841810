import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { Checkbox } from "flwww";
import { AppContext } from "../context/AppContext";

export default class Settings extends Component {
  static contextType = AppContext;
  state = {
    pax: 0,
    newNote: "",
    notes: [],
    sameDay: false,
  };

  componentDidMount() {
    let paxDoc = this.context.outlet === "TCS" ? "pax" : "paxCMPB";
    let noticesDoc = this.context.outlet === "TCS" ? "notices" : "noticesCMPB";
    let pageDoc = this.context.outlet === "TCS" ? "page" : "pageCMPB";
    db.collection("settings")
      .doc(paxDoc)
      .get()
      .then((doc) => {
        this.setState({
          pax: doc.data().limit,
        });
      });
    db.collection("settings")
      .doc(noticesDoc)
      .get()
      .then((doc) => {
        this.setState({
          notes: doc.data().message,
        });
      });
    db.collection("settings")
      .doc(pageDoc)
      .get()
      .then((doc) => {
        this.setState({
          sameDay: doc.data().sameDay,
        });
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onPaxChange = (e) => {
    this.setState({ [e.target.id]: parseInt(e.target.value) });
  };

  onBoolChange = (e) => {
    let pageDoc = this.context.outlet === "TCS" ? "page" : "pageCMPB";
    this.setState({ [e.target.name]: e.target.checked }, () => {
      db.collection("settings").doc(pageDoc).update({
        sameDay: this.state.sameDay,
      });
    });
  };

  changeLimit = () => {
    let paxDoc = this.context.outlet === "TCS" ? "pax" : "paxCMPB";
    db.collection("settings").doc(paxDoc).update({
      limit: this.state.pax,
    });
  };

  onNoteListChange = (index, id) => {
    let newList = [...this.state.notes];
    newList.splice(index, 1, this.state[`note${index}`]);
    this.setState({
      notes: newList,
      [id]: null,
    });
  };

  addNote = () => {
    let list = [...this.state.notes];
    list.push(this.state.newNote);
    this.setState({ notes: list, newNote: "" });
  };

  saveChanges = () => {
    let noticesDoc = this.context.outlet === "TCS" ? "notices" : "noticesCMPB";
    db.collection("settings").doc(noticesDoc).update({
      message: this.state.notes,
    });
  };

  removeNote = (index) => {
    let copy = [...this.state.notes];
    copy.splice(index, 1);
    this.setState({ notes: copy });
  };

  render() {
    return (
      <div className="settings-page">
        <div className="pax-limit">
          <label htmlFor="">Reservation Pax Limit</label>
          <div className="pax-input">
            <input
              type="number"
              name="pax"
              id="pax"
              value={this.state.pax}
              onChange={this.onPaxChange}
            />
            <button className="save-btn" onClick={this.changeLimit}>
              Save
            </button>
          </div>
        </div>
        <hr />
        <div className="page-notes">
          <label htmlFor="">Page Notes</label>
          {this.state.notes.map((note, index) => {
            return (
              <div className="note-line" key={note}>
                <input
                  id={`note${index}`}
                  type="text"
                  value={note}
                  onChange={this.onChange}></input>
                <button
                  className="save-btn"
                  onClick={() => this.onNoteListChange(index, `note${index}`)}>
                  Confirm
                </button>
                <button
                  className="remove-btn"
                  onClick={() => this.removeNote(index)}>
                  Remove
                </button>
              </div>
            );
          })}
          <hr style={{ opacity: 0.4 }} />
          <div className="note-line">
            <input
              type="text"
              name="newNote"
              id="newNote"
              onChange={this.onChange}
            />
            <button className="action-btn" onClick={this.addNote}>
              Add New Note
            </button>
          </div>
          <button className="save-changes save-btn" onClick={this.saveChanges}>
            Save Changes
          </button>
        </div>
        <hr />
        <div className="same-day">
          <label htmlFor="">Same Day Reservations</label>
          <Checkbox
            name="sameDay"
            onChange={this.onBoolChange}
            checked={this.state.sameDay}></Checkbox>
        </div>
      </div>
    );
  }
}
