import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Select from "react-select";
import { db } from "../Firebase/firebase";
import { message } from "flwww";
import MinuteBlock from "./MinuteBlock";
import { AppContext } from "../context/AppContext";

export default class HourBlock extends Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      selectedDays: [],
      loading: "Update",
      adminEmail: "",
      viewerEmail: "",
      date: "",
      dayOfWeek: "",
      hours: [],
      minutes: [],
      dateId: "",
      isVisible: false,
      options: [
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
        { value: 21, label: "21" },
        { value: 22, label: "22" },
      ],
      default: [
        { value: 8, label: "8", minutes: [] },
        { value: 9, label: "9", minutes: [] },
        { value: 10, label: "10", minutes: [] },
        { value: 11, label: "11", minutes: [] },
        { value: 12, label: "12", minutes: [] },
        { value: 13, label: "13", minutes: [] },
        { value: 14, label: "14", minutes: [] },
        { value: 15, label: "15", minutes: [] },
        { value: 16, label: "16", minutes: [] },
        { value: 17, label: "17", minutes: [] },
        { value: 18, label: "18", minutes: [] },
        { value: 19, label: "19", minutes: [] },
        { value: 20, label: "20", minutes: [] },
        { value: 21, label: "21", minutes: [] },
        { value: 22, label: "22", minutes: [] },
      ],
      defaultSaturday: [
        { value: 8, label: "8", minutes: [{ label: "00", value: 0 }] },
        {
          value: 9,
          label: "9",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 10, label: "10", minutes: [{ label: "00", value: 0 }] },
        {
          value: 11,
          label: "11",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 12, label: "12", minutes: [{ label: "00", value: 0 }] },
        {
          value: 13,
          label: "13",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 14, label: "14", minutes: [{ label: "00", value: 0 }] },
        { value: 15, label: "15", minutes: [{ label: "00", value: 0 }] },
        { value: 16, label: "16", minutes: [] },
        { value: 17, label: "17", minutes: [{ label: "00", value: 0 }] },
        {
          value: 18,
          label: "18",
          minutes: [
            { label: "00", value: 0 },
            { label: "30", value: 30 },
          ],
        },
        { value: 19, label: "19", minutes: [{ label: "00", value: 0 }] },
        { value: 20, label: "20", minutes: [] },
        { value: 21, label: "21", minutes: [] },
        { value: 22, label: "22", minutes: [{ label: "30", value: 30 }] },
      ],
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  toggleModal = () => {
    // This function change the state of the modal visibility (e.g. this.state.modalIsVisible)
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleDayClick = (day, { selected, disabled }) => {
    let date = day.getDate();
    let month = day.getMonth();
    let year = day.getFullYear();
    let dayOfWeek = day.getDay();
    db.collection("blocked")
      .where("date", ">=", new Date(year, month, date, 0, 0, 0, 0))
      .where("date", "<=", new Date(year, month, date, 23, 0, 0, 0))
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        console.log(querySnapshot);
        if (querySnapshot.size === 0) {
          if (dayOfWeek == 6) {
            this.setState({
              hours: [],
              minutes: this.state.defaultSaturday,
              dateId: "",
              currentTimings: [],
            });
          } else {
            this.setState({
              hours: [],
              minutes: this.state.default,
              dateId: "",
              currentTimings: [],
            });
          }
        } else {
          querySnapshot.forEach((doc) => {
            let info = doc.data();
            this.setState({
              hours: info.hours,
              minutes: info.minutes,
              dateId: doc.id,
            });
          });
        }
      });
    if (disabled) {
      return;
    }
    this.setState({
      date: day,
      dayOfWeek: day.getDay(),
    });
  };
  onChange = (e) => {
    let { hours } = this.state;
    let check = hours.indexOf(e.target.value);
    if (check === -1) {
      this.setState({ hours: [...hours, e.target.value] });
    } else {
      hours.splice(check, 1);
      this.setState({ hours: [...hours] });
    }
  };

  checkedOrNot = (num) => {
    if (this.state.hours.indexOf(num) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  onSelectChange(value, { action, removedValue }) {
    if (value === null) {
      value = [];
    }
    this.setState({ hours: value });
  }

  updateHours = (e) => {
    e.preventDefault();
    if (this.state.dateId !== "") {
      db.collection("blocked")
        .doc(this.state.dateId)
        .update({ hours: this.state.hours, minutes: this.state.minutes });
      message("updated!", "success", 3);
    } else {
      db.collection("blocked").add({
        date: this.state.date,
        hours: this.state.hours,
        minutes: this.state.minutes,
        outlet: this.context.outlet,
      });
      message("updated!", "success", 3);
    }
  };
  render() {
    return (
      <div className="blocked-hours">
        <div className="blocked-hour">
          <h1>Hours</h1>
          <form>
            <div className="date-time full-width">
              {" "}
              <div className="form-group">
                <DayPicker
                  onDayClick={this.handleDayClick}
                  selectedDays={this.state.date}
                  onDayChange={(day) => console.log(day)}
                  showOverlay={true}
                />
              </div>
            </div>

            <button
              className="full-width"
              type="submit"
              onClick={this.updateHours}>
              {this.state.loading}
            </button>
            {this.state.date !== "" ? (
              <Select
                isMulti
                value={this.state.hours}
                name="hours"
                onChange={this.onSelectChange}
                options={this.state.options}
                className="basic-multi-select full-width"
                classNamePrefix="select"
                menuPlacement="top"
              />
            ) : null}
          </form>
        </div>
        <MinuteBlock></MinuteBlock>
      </div>
    );
  }
}
