import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";
export default class SelectTable extends Component {
  static contextType = AppContext;
  state = {
    areas: [],
  };

  componentDidMount() {
    db.collection("tables")
      .doc(this.context.outlet)
      .onSnapshot((doc) => {
        this.setState({ areas: doc.data().areas });
      });
  }
  render() {
    return (
      <div className="select-table-box">
        {this.state.areas.map((area) => {
          return (
            <div className="select-table-area">
              {/* <h4>{area.name}</h4> */}
              <hr />
              <div className="select-tables">
                {area.tableList.map((table) => {
                  return (
                    <button
                      className="select-table-btn"
                      onClick={() => {
                        this.props.changeTable(this.props.whichTable, table);
                        this.props.close();
                      }}>
                      {table}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
