import React, { Component, createContext } from "react";
export const AppContext = createContext();

export default class AppContextProvider extends Component {
  state = {
    unaddressed: 0,
    unaddressedToday: 0,
    outlet: "CM-PB",
    admin: false,
    viewer: false,
    isMobile: window.innerWidth > 500 ? false : true,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  setCount = (unaddressed) => {
    this.setState({ unaddressed });
  };
  handleResize = () => {
    if (window.innerWidth <= 500) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
  setCountToday = (unaddressedToday) => {
    this.setState({ unaddressedToday });
  };
  setAdmin = (bool) => {
    this.setState({ admin: bool });
  };
  setViewer = (bool) => {
    this.setState({ viewer: bool });
  };
  setMasterAdmin = () => {
    this.setState({ masterAdmin: true });
  };
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setCount: this.setCount,
          setCountToday: this.setCountToday,
          setAdmin: this.setAdmin,
          setViewer: this.setViewer,
          setMasterAdmin: this.setMasterAdmin,
        }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
