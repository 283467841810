import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import TimingControls from "./TimingControls";
import { AppContext } from "../context/AppContext";
import { db } from "../Firebase/firebase";
import moment from "moment";

export default class BlockedTimings extends Component {
  static contextType = AppContext;
  state = {
    defaultSelected: true,
    dayType: "weekday",
    date: new Date(),
    selected: false,
  };
  componentDidMount() {}
  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }
    this.setState({ selected: false, defaultSelected: false }, () => {
      let dayOfWeek = day.getDay();
      this.setState({
        dayType: dayOfWeek === 0 || dayOfWeek === 6 ? "weekend" : "weekday",
        date: day,
        selected: true,
      });
    });
  };
  render() {
    let { date, defaultSelected, dayType, selected } = this.state;
    return (
      <div className="admin-timings-page">
        <div className="admin-date-select">
          <DayPicker
            onDayClick={this.handleDayClick}
            onDayChange={(day) => console.log(day)}
            showOverlay={true}
            selectedDays={this.state.date}
          />
          <div className="defaults-box">
            <button
              onClick={() => {
                this.setState({ selected: false }, () =>
                  this.setState({
                    selected: true,
                    dayType: "weekday",
                    defaultSelected: true,
                  })
                );
              }}>
              Weekday
            </button>
            <button
              onClick={() => {
                this.setState({ selected: false }, () =>
                  this.setState({
                    selected: true,
                    dayType: "weekend",
                    defaultSelected: true,
                  })
                );
              }}>
              Weekend
            </button>
          </div>
        </div>
        {selected ? (
          <TimingControls
            date={date}
            defaultSelected={defaultSelected}
            type={dayType}></TimingControls>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
