import React, { Component } from 'react'
import { db, auth } from '../Firebase/firebase';
import {Dropdown} from 'flwww'

export default class Accounts extends Component {
    state = {
        users: [],
        createNew: false,
        currentUserID: '',
        currentUserEmail: '',
        newEmail: '',
        newPassword: ''
    }

    componentDidMount() {
        db.collection('users').where('outlet', '==', 'TCS').onSnapshot(querySnapShot => {
            let userData = [];
            querySnapShot.forEach(doc => {
                let user = doc.data();
                userData.push({...user, id: doc.id })
            })
            this.setState({
                users: userData
            })
        })
    }

    changeRole = (roleData) => {
        //
        fetch(
          "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/setRole",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({email: this.state.currentUserEmail, ...roleData}),
          }
        )
        .then((res) => res.json())
          .then((data) => {
            if (data.message) {
              console.log(data.message);
            }
            else {
                console.log(data);
                console.log("Failed")
            }
          });
    }

    createNewAccount = () => {
        let {newEmail, newPassword} = this.state;
        auth.createUserWithEmailAndPassword(newEmail, newPassword)
        .then((userCredential) => {
            // Signed in 
            var user = userCredential.user;
            // ...
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            // ..
        });
    }

    testSms = () => {
        fetch(
          "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/testSMS",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'mode': 'no-cors'
            },
            body: JSON.stringify({ID: 131930002, Password: "Spiceoflife@1", Mobile: 6598522802, Type: "AUTO", Message: "testing"}),
          }
        )
          .then((res) => res.json())
          .then((data) => {
           console.log(data);
          });
    }

    
    

    render() {
        let {users, createNew} =  this.state;
        const roleList = [
                <button className='dropdown-btn' onClick={() => this.changeRole({
                    isMasterAdmin: false,
                    isAdmin: false,
                    isViewOnly: false
                })}>None</button>, 
                <button className='dropdown-btn' onClick={() => this.changeRole({
                    isMasterAdmin: false,
                    isAdmin: true,
                    isViewOnly: false
                })}>Admin</button>, 
                <button className='dropdown-btn' onClick={() => this.changeRole({
                    isMasterAdmin: true,
                    isAdmin: false,
                    isViewOnly: false
                })}>Master Admin</button>, 
                <button className='dropdown-btn' onClick={() => this.changeRole({
                    isMasterAdmin: false,
                    isAdmin: false,
                    isViewOnly: true
                })}>View Only</button>
            ];

        const actionList = [
                <button className='dropdown-btn'>Change Password</button>, 
                <button className='dropdown-btn'>Delete Account</button>, 
            ];
        return (
            <div className='account-management'>
{/*                 <button onClick={this.testSms}>test</button> */}
                <div className="user-grid">
                    {users.map(user => {
                        let currentRole = user.isMasterAdmin ? "Master Admin" : user.isAdmin ? "Admin" : user.isViewOnly ? "View Only" : "None"
                        return <React.Fragment>
                            <span className='acc-email'>{user.email}</span>
                            <div className="role-list">
                                <Dropdown elementList={ roleList }>
                                    <button type="primary" className='role-btn' onClick={() => this.setState({currentUserID: user.id, currentUserEmail: user.email})}>{currentRole}</button>
                                </Dropdown>
                            </div>
                            <div className="acc-actions">
                                <Dropdown elementList={ actionList }>
                                    <button type="primary" className='action-btn' onClick={() => this.setState({currentUserID: user.id, currentUserEmail: user.email})}><i class="im im-menu-dot-v"></i></button>
                                </Dropdown>
                            </div>
                        </React.Fragment>
                    })}
                    { !createNew ? <button>Create New</button> : <div></div>}
                </div>
            </div>
        )
    }
}
