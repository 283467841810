import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "rc-datepicker/lib/style.css";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../context/AppContext";

class Edit extends Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      name: "",
      mobile: "",
      email: "",
      pax: "",
      date: "",
      time: "",
      seatPref: "",
      contactPref: "Email",
      remarks: "",
      loading: "Save",
      checked: false,
      hour: 0,
      ht: "No",
      defaultTime: moment(),
    };
    this.onTimeChange = this.onTimeChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.reserve) {
      db.collection("reservations")
        .doc(this.props.match.params.reserve)
        .get()
        .then((doc) => {
          this.setState(
            {
              ...doc.data(),
            },
            () => {
              let timestring = this.state.time;
              timestring = timestring.split(":");
              console.log(timestring);
              this.setState(
                {
                  date: this.state.date.toDate(),
                  defaultTime: this.state.defaultTime.set({
                    hour: timestring[0],
                    minute: timestring[1],
                  }),
                },
                () => {
                  this.setState({
                    time: this.state.defaultTime,
                  });
                }
              );
            }
          );
        });
    }
  }

  onChange = (e) => {
    console.log(this.state.date);
    this.setState({ [e.target.id]: e.target.value });
  };

  onTimeChange(value) {
    // do something
    console.log(value && value.format("HH:mm"));
    this.setState({ time: value, hour: value.hour() });
  }

  onDateChange = (jsDate, dateString) => {
    console.log(jsDate);
    this.setState(
      {
        date: dateString.slice(0, 10),
      },
      console.log(this.state.date)
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.email == "" ||
      this.state.name == "" ||
      this.state.pax == "" ||
      this.state.mobile == "" ||
      this.state.time == "" ||
      this.state.seatPref == "" ||
      this.state.contactPref == ""
    ) {
      this.setState({
        checked: true,
      });
      alert("Please fill in the required details!");
    } else if (this.state.date === "") {
      alert("Please select a date!");
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      ) === false
    ) {
      alert("Please enter a valid email address.");
    } else {
      this.setState({
        loading: "Submitting...",
      });
      const newReserve = {
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        pax: this.state.pax,
        date: new Date(this.state.date),
        time: this.state.time.format("HH:mm"),
        seatPref: this.state.seatPref,
        contactPref: this.state.contactPref,
        remarks: this.state.remarks,
        editedAt: new Date(),
        ht: this.state.ht,
      };

      db.collection("reservations")
        .doc(this.props.match.params.reserve)
        .update(newReserve);
      this.props.history.goBack();
      /*fetch(
        "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/sendReservationMail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newReserve),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.message) {
            console.log("success");
            this.props.history.replace("/confirmation");
          } else {
            console.log("Failed");
          }
        });*/
    }
  };

  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }
    this.setState({
      date: day,
      dayOfWeek: day.getDay(),
    });
  };

  disabledHours(daySelect) {
    let day = daySelect.getDay();
    let month = daySelect.getMonth();
    let date = daySelect.getDate();
    if (month === 7 && date == 30) {
      return [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 22, 23,
      ];
    }
    if (day == 0 || day == 6) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 22, 23];
    } else {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 22, 23];
    }
  }
  disabledMinutes(time) {
    let hour = time;
    if (hour === 8 || hour === 10) {
      return [0, 15];
    }
  }
  render() {
    let today = new Date();
    let day = today.getDay();
    if (day === 0 || day === 6 || day === 5) {
      today.setDate(today.getDate() + 1);
    }
    return (
      <div id="create">
        <form>
          <div className="date-time full-width">
            {" "}
            <div className="form-group">
              <DayPicker
                onDayClick={this.handleDayClick}
                selectedDays={this.state.date}
                onDayChange={(day) => console.log(day)}
                showOverlay={true}
                disabledDays={[{ before: today }]}
              />
            </div>
            <div className="form-group">
              {this.state.date !== "" ? (
                <React.Fragment>
                  <label
                    style={
                      this.state.checked && this.state.time == ""
                        ? { color: "red" }
                        : null
                    }>
                    Time*
                  </label>
                  <TimePicker
                    defaultValue={this.state.defaultTime}
                    showSecond={false}
                    onChange={this.onTimeChange}
                    allowEmpty={false}
                    minuteStep={15}
                    disabledHours={() => this.disabledHours(this.state.date)}
                    disabledMinutes={() =>
                      this.disabledMinutes(this.state.hour)
                    }></TimePicker>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.name == ""
                  ? { color: "red" }
                  : null
              }>
              Full Name*
            </label>
            <input
              required
              value={this.state.name}
              onChange={this.onChange}
              id="name"
              type="text"></input>
          </div>
          <div className="form-group" id="mobile-no">
            <label
              style={
                this.state.checked && this.state.mobile == ""
                  ? { color: "red" }
                  : null
              }>
              Mobile No*
            </label>
            <input
              required
              value={this.state.mobile}
              onChange={this.onChange}
              id="mobile"
              type="number"></input>
          </div>
          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.email == ""
                  ? { color: "red" }
                  : null
              }>
              Email*
            </label>
            <input
              required
              value={this.state.email}
              onChange={this.onChange}
              id="email"
              type="Email"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"></input>
          </div>

          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.pax == ""
                  ? { color: "red" }
                  : null
              }>
              No of Pax*
            </label>
            <input
              required
              min="1"
              max="8"
              value={this.state.pax}
              onChange={this.onChange}
              type="number"
              id="pax"></input>
          </div>

          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.seatPref == ""
                  ? { color: "red" }
                  : null
              }>
              Seat Preference*
            </label>

            <div className="radio-div">
              <input
                checked={this.state.seatPref === "indoor"}
                required
                value="indoor"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Indoor</span>
            </div>
            <div className="radio-div">
              <input
                checked={this.state.seatPref === "outdoor"}
                required
                value="outdoor"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Outdoor</span>
            </div>
            <div className="radio-div">
              <input
                checked={this.state.seatPref === "Bar"}
                required
                value="Bar"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Bar</span>
            </div>
          </div>
          {this.context.outlet === "TCS" ? (
            <div className="form-group">
              <label
                style={
                  this.state.checked && this.state.ht == ""
                    ? { color: "red" }
                    : null
                }>
                Are you joining us for High Tea?
              </label>
              <div className="radio-div">
                <input
                  required
                  value="Yes"
                  onChange={this.onChange}
                  id="ht"
                  name="ht"
                  type="radio"></input>
                <span>Yes</span>
              </div>
              <div className="radio-div">
                <input
                  required
                  value="No"
                  onChange={this.onChange}
                  id="ht"
                  name="ht"
                  type="radio"></input>
                <span>No</span>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label
                style={
                  this.state.checked && this.state.contactPref == ""
                    ? { color: "red" }
                    : null
                }>
                Contact Preference*
              </label>

              <div className="radio-div">
                <input
                  required
                  value="Email"
                  onChange={this.onChange}
                  id="contactPref"
                  name="contactPref"
                  type="radio"></input>
                <span>Email</span>
              </div>
              <div className="radio-div">
                <input
                  required
                  value="Mobile"
                  onChange={this.onChange}
                  id="contactPref"
                  name="contactPref"
                  type="radio"></input>
                <span>Mobile</span>
              </div>
            </div>
          )}

          <div className="form-group full-width">
            <label>Special Requests</label>
            <textarea
              value={this.state.remarks}
              onChange={this.onChange}
              id="remarks"></textarea>
          </div>

          <button className="full-width" type="submit" onClick={this.onSubmit}>
            {this.state.loading}
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(Edit);
