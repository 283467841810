import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";

export default class AreaRow extends Component {
  static contextType = AppContext;
  confirmArrival = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      finished: !this.props.data.finished,
    });
  };
  text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  render() {
    console.log(this.props.current > this.props.data.dateTime);
    return (
      <div
        onClick={this.confirmArrival}
        className={
          this.props.data.cancelled ? "area-row cancelled" : "area-row"
        }
        style={
          this.props.data.finished
            ? { backgroundColor: "#3ee67e", color: "#000" }
            : this.props.data.declined
            ? { backgroundColor: "#f71b39" }
            : this.props.current > this.props.data.dateTime
            ? { backgroundColor: "orange", color: "#000" }
            : null
        }>
        {this.props.others ? (
          <span style={{ width: "10%" }}>
            {this.props.data.tableNo ? this.props.data.tableNo : ""}{" "}
          </span>
        ) : null}
        <span>{this.props.data.time}</span>
        <span style={{ width: "65%", textAlign: "center" }}>
          {this.context.isMobile
            ? this.text_truncate(
                this.props.data.name,
                this.props.others ? 12 : 10,
                "..."
              )
            : this.text_truncate(
                this.props.data.name,
                this.props.others ? 20 : 14,
                "..."
              )}
        </span>
        <span>{this.props.data.pax}</span>
      </div>
    );
  }
}
