import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import AppContextProvider from "./components/context/AppContext";
import { ThemeProvider } from 'flwww'

const theme = {
  primaryColor: "#B08C19"
};

ReactDOM.render(
  <Router>
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppContextProvider>
  </Router>,
  document.getElementById("root")
);
registerServiceWorker();
